<template>
  <b-card-code
    title="Students Enrolled in Event"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    
    <b-col
      md="12"
      class="my-1"
    >
    <!--
      <b-button    
        variant="primary"
        class="mb-1 float-right"
        to="/create-course"
      >
            Create Lesson
      </b-button>
      -->
    </b-col>
    <b-table
      :fields="fields"
      :items="userLists"
      responsive
      class="mb-0"
    >

      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>
    
      <!-- A custom formatted column -->
      <template #cell(name)="data">
        <span class="text-nowrap">{{ data.item.name }} {{data.item.surname}}</span>
      </template>
      <template #cell(status)="data">
        <span class="text-nowrap">Enrolled</span>
      </template>
      <template #cell(id)="data">
        <b-row>
            <b-col lg="3" class="pb-2"><b-button variant="outline-danger" size="sm" @click="deleteEvent(data.item.id)"><b-icon icon="person-x-fill"></b-icon></b-button></b-col>
        </b-row>  
              
     </template>
    </b-table>
    <!-- pagination -->      
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BProgress,BButton, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import EventService  from '@/services/event.service.js';

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'Sr#',
        // A column that needs custom formatting
        { key: 'name'},
        { key: 'email', label: 'Email'},
        { key: 'telephone_num', label: 'Telephone Number'},
        { key: 'id_num', label: 'DNI'},
        { key: 'status'},
        {key: 'id', label: 'Action'},
      ],
      userLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
      id: this.$route.params.eventId,
    }
  },
  watch:{
        '$route'(to) {
            this.id = to.params.eventId;
        } 
    },
  methods: {
    getUsers() {
        this.showOverlay = true;
        EventService.getUserByEvent(
            this.id,
        ).then(response => {
            this.userLists = response.data.data.users;
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            console.log(error);
        });
    },
    deleteEvent(userId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showOverlay = true;
          EventService.cancelledEvent(userId, this.id).then(response => {
                    this.$swal({
                      icon: 'success',
                      title: 'Deleted!',
                      text: 'User has been Un enroll from event.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  
                  //const userIndex = this.userLists.findIndex(user => user.id === id);
                  //this.studentLists.splice(userIndex, 1);   
                  this.showOverlay = false;
                  this.$router.push('/events');
                    
                }).catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
          
        }
      })
    }
    
  },
  created() {
    this.getUsers();
  }
}
</script>
